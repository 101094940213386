import * as React from 'react';
import { twMerge } from 'tailwind-merge';

export interface ImageWithFallbackProps {
    className?: string;
    children?: React.ReactNode;
    src?: string;
    fallbackSrc?: string;
    alt: string;
}

export function ImageWithFallback({
    className,
    children,
    src,
    fallbackSrc = '/images/avatar/user.svg',
    alt,
    ...rest
}: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
> &
    ImageWithFallbackProps) {
    return (
        <img
            loading="eager"
            className={twMerge(`object-cover ${className}`)}
            src={src}
            onError={({ currentTarget }) => (currentTarget.src = fallbackSrc)}
            alt={alt}
            {...rest}
        >
            {children}
        </img>
    );
}
